import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ValidationMessagesService } from '../services/validation-messages.service';
import { ApiService } from '../services/api.service';
import { NewContactUsCompletedModel } from '../models/new-contact-us-completed-model';
import { NewContactUsModel } from '../models/new-contact-us-model';

@Component({
  selector: 'app-request-content',
  templateUrl: './request-content.component.html',
  styleUrls: ['./request-content.component.scss']
})
export class RequestContentComponent implements OnInit, OnDestroy {
  private newContactUsModel: NewContactUsModel;
  private newContactUsCompletedModel: NewContactUsCompletedModel;
  public contactUsError = false;
  public contactUsSuccess = false;
  public contactUsErrorMessage: string;
  public contactUsSuccessMessage: string;
  public contactUsForm: FormGroup;
  public contactUsActiveValidationMessages = {
    fullName: '',
    emailAddress: '',
    mobileNumber: ''
  };
  private readonly contactUsValidationMessages = {
    fullName: {
      required: 'The full name is required.',
      minLength: 'The full name has a minimum length of 1 and maximum length of 100.',
      maxLength: 'The full name has a minimum length of 1 and maximum length of 100.'
    },
    emailAddress: {
      required: 'The email address is required.',
      validPhoneNumber: 'The email address must be a valid email address.',
      minLength: 'The email address has a minimum length of 5 and maximum length of 100.',
      maxLength: 'The email address has a minimum length of 5 and maximum length of 100.'
    },
    mobileNumber: {
      required: 'The mobile number is required.',
      validPhoneNumber: 'The mobile number must be a valid phone number.',
      minLength: 'The mobile number has a minimum length of 10 and maximum length of 10.',
      maxLength: 'The mobile number has a minimum length of 10 and maximum length of 10.'
    },
  };

  private subscriptions: Array<Subscription> = [];

  constructor(
    private readonly http: HttpClient,
    private readonly formBuilder: FormBuilder,
    private readonly validationMessagesService: ValidationMessagesService,
    private readonly apiService: ApiService,
  ) {
    window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
  }

  ngOnInit() {
    this.initialiseContactUsForm();
  }

  public ngOnDestroy() {
    try {
      this.subscriptions.forEach(subscription => {
        subscription.unsubscribe();
      });
    } catch (error) {
      console.error('ngOnDestroy() ', error.message);
    }
  }

  public initialiseContactUsForm() {
    this.contactUsForm = this.formBuilder.group({
      fullName: ['',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100)
        ])],
      emailAddress: ['',
        Validators.compose([
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(100)
        ])],
      mobileNumber: ['',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10)
        ])],
      message: ['']
    });

    this.subscriptions = this.validationMessagesService.setupMessages(this.contactUsValidationMessages,
      this.contactUsActiveValidationMessages,
      this.contactUsForm);
  }

  submitContactUs() {
    if (this.contactUsForm.valid) {
      this.newContactUsModel = this.contactUsForm.value;
      this
        .apiService
        .sendContactUsEmail(this.newContactUsModel)
        .then((response) => {
          this.newContactUsCompletedModel = response;
          this.contactUsSuccess = true;
          this.contactUsSuccessMessage = this.newContactUsCompletedModel.message;
          this.contactUsForm.reset();
          window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
        })
        .catch((error: any) => {
          this.contactUsError = true;

          window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
          if (error.status === 500) {
            this.contactUsErrorMessage = 'Please contact an administrator.';
          } else {
            this.contactUsErrorMessage = error.error.title || error.error.detail;
          }
        }).finally(() => {
          this.hideAlerts();
        });
    } else {
      window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
      this.validationMessagesService.updateValidationMessages(this.contactUsValidationMessages,
        this.contactUsActiveValidationMessages,
        this.contactUsForm);
      this.contactUsError = true;
      this.contactUsErrorMessage = 'Please ensure you provide all the required fields.';
    }
  }
  private hideAlerts() {
    setTimeout(() => {
      this.contactUsError = false;
      this.contactUsSuccess = false;
    }, 10000);
  }
}
