import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CallToActionComponent } from './call-to-action/call-to-action.component';
import { WhatWeOfferComponent } from './what-we-offer/what-we-offer.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { FooterComponent } from './footer/footer.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { PricingComponent } from './pricing/pricing.component';
import { CourseDetailComponent } from './course-detail/course-detail.component';
import { HomeComponent } from './home/home.component';
import { RequestContentComponent } from './request-content/request-content.component';
import { HttpClientModule } from '@angular/common/http';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    AppComponent,
    CallToActionComponent,
    WhatWeOfferComponent,
    AboutUsComponent,
    FooterComponent,
    HowItWorksComponent,
    PricingComponent,
    CourseDetailComponent,
    HomeComponent,
    RequestContentComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    ScrollToModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
