import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ValidationMessagesService } from '../services/validation-messages.service';
import { ApiService } from '../services/api.service';
import { NewContactUsCompletedModel } from '../models/new-contact-us-completed-model';
import { NewContactUsModel } from '../models/new-contact-us-model';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent {
}
