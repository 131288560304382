import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-what-we-offer',
  templateUrl: './what-we-offer.component.html',
  styleUrls: ['./what-we-offer.component.scss']
})
export class WhatWeOfferComponent implements OnInit {

  public courses: Array<Course> = new Array<Course>();
  public quickListCourse: Array<Course> = new Array<Course>();
  public allCoursesVisible: boolean = false;

  constructor(
    private readonly router: Router
  ) {
    this.courses =
    [
      {
        title: 'English Language',
        level: 'Junior',
        imagePath: '../../assets/Images/101.png',
        description: '',
        id: '101'
      },
      {
        title: 'English Literature',
        level: 'Junior',
        imagePath: '../../assets/Images/120.png',
        description: '',
        id: '120'
      },
      {
        title: 'French',
        level: 'Junior',
        imagePath: '../../assets/Images/202.png',
        description: '',
        id: '202'
      },
      {
        title: 'SISWATI',
        level: 'Junior',
        imagePath: '../../assets/Images/207.png',
        description: '',
        id: '207'
      },
      {
        title: 'Mathematics',
        level: 'Junior',
        imagePath: '../../assets/Images/309.png',
        description: '',
        id: '309'
      },
      {
        title: 'Agricutlure',
        level: 'Junior',
        imagePath: '../../assets/Images/516.png',
        description: '',
        id: '516'
      },
      {
        title: 'Additional Mathematics',
        level: 'Junior',
        imagePath: '../../assets/Images/519.png',
        description: '',
        id: '519'
      },
      {
        title: 'Bookeeping & Accounts',
        level: 'Junior',
        imagePath: '../../assets/Images/520.png',
        description: '',
        id: '520'
      },
      {
        title: 'Business Studies',
        level: 'Junior',
        imagePath: '../../assets/Images/521.png',
        description: '',
        id: '521'
      },
      {
        title: 'Development Studies',
        level: 'Junior',
        imagePath: '../../assets/Images/524.png',
        description: '',
        id: '524'
      },
      {
        title: 'Geography',
        level: 'Junior',
        imagePath: '../../assets/Images/527.png',
        description: '',
        id: '527'
      },
      {
        title: 'History',
        level: 'Junior',
        imagePath: '../../assets/Images/530.png',
        description: '',
        id: '530'
      },
      {
        title: 'Religous Studies',
        level: 'Junior',
        imagePath: '../../assets/Images/533.png',
        description: '',
        id: '533'
      },
      {
        title: 'Siswati as a First Language',
        level: 'Senior',
        imagePath: '../../assets/Images/6870.png',
        description: '',
        id: '6870'
      },
      {
        title: 'Siswati as a Second Language',
        level: 'Senior',
        imagePath: '../../assets/Images/6871.png',
        description: '',
        id: '6871'
      },
      {
        title: 'English Language',
        level: 'Senior',
        imagePath: '../../assets/Images/6873.png',
        description: '',
        id: '6873'
      },
      {
        title: 'English Literature',
        level: 'Senior',
        imagePath: '../../assets/Images/6875.png',
        description: '',
        id: '6875'
      },
      {
        title: 'Mathematics',
        level: 'Senior',
        imagePath: '../../assets/Images/6880.png',
        description: '',
        id: '6880'
      },
      {
        title: 'Biology',
        level: 'Senior',
        imagePath: '../../assets/Images/6884.png',
        description: '',
        id: '6884'
      },
      {
        title: 'Physical Science',
        level: 'Senior',
        imagePath: '../../assets/Images/6888.png',
        description: '',
        id: '6888'
      },
      {
        title: 'Geography',
        level: 'Senior',
        imagePath: '../../assets/Images/6890.png',
        description: '',
        id: '6890'
      },
      {
        title: 'History',
        level: 'Senior',
        imagePath: '../../assets/Images/6891.png',
        description: '',
        id: '6891'
      },
      {
        title: 'Religous Education',
        level: 'Senior',
        imagePath: '../../assets/Images/6893.png',
        description: '',
        id: '6893'
      },
      {
        title: 'Accounts',
        level: 'Senior',
        imagePath: '../../assets/Images/6896.png',
        description: '',
        id: '6896'
      },
      {
        title: 'Business Studies',
        level: 'Senior',
        imagePath: '../../assets/Images/6897.png',
        description: '',
        id: '6897'
      },
      {
        title: 'Economics',
        level: 'Senior',
        imagePath: '../../assets/Images/6899.png',
        description: '',
        id: '6899'
      },

  ];

    this.quickListCourse =
      [
        {
          title: 'English Language',
          level: 'Junior',
          imagePath: '../../assets/Images/101.png',
          description: '',
          id: '101'
        },
        {
          title: 'English Literature',
          level: 'Junior',
          imagePath: '../../assets/Images/120.png',
          description: '',
          id: '120'
        },
        {
          title: 'French',
          level: 'Junior',
          imagePath: '../../assets/Images/202.png',
          description: '',
          id: '202'
        },
        {
          title: 'SISWATI',
          level: 'Junior',
          imagePath: '../../assets/Images/207.png',
          description: '',
          id: '207'
        }
      ]
  }

  ngOnInit() {
  }

  public loadAllCourses(): void {
    this.allCoursesVisible = true;
    this.quickListCourse = this.courses;
  }

  public hideAllCourses(): void {
    this.allCoursesVisible = false;
    this.quickListCourse.splice(4, this.courses.length - 4);
  }

  public navigateTo(id: string): void {
    this.router.navigate(['/course-detail/' + id]).catch(
      error => {
        console.log(error);
      }
    );
  }
}

export class Course {
  public title: string;
  public description: string;
  public level: string;
  public imagePath: string;
  public id: string;
}
