import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Course } from '../what-we-offer/what-we-offer.component';

@Component({
  selector: 'app-course-detail',
  templateUrl: './course-detail.component.html',
  styleUrls: ['./course-detail.component.scss']
})
export class CourseDetailComponent implements OnInit {
  public id: string;
  public course: Course;
  public courses: Array<Course> = new Array<Course>();
  constructor(
    private readonly route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');

    this.searchCourseById(this.id);
  }

  private searchCourseById(id: string): void {
    this.courses =
    [
      {
        title: 'English Language',
        level: 'Junior',
        imagePath: '../../assets/Images/101.png',
        description: '',
        id: '101'
      },
      {
        title: 'English Literature',
        level: 'Junior',
        imagePath: '../../assets/Images/120.png',
        description: '',
        id: '120'
      },
      {
        title: 'French',
        level: 'Junior',
        imagePath: '../../assets/Images/202.png',
        description: '',
        id: '202'
      },
      {
        title: 'SISWATI',
        level: 'Junior',
        imagePath: '../../assets/Images/207.png',
        description: '',
        id: '207'
      },
      {
        title: 'Mathematics',
        level: 'Junior',
        imagePath: '../../assets/Images/309.png',
        description: '',
        id: '309'
      },
      {
        title: 'Agricutlure',
        level: 'Junior',
        imagePath: '../../assets/Images/516.png',
        description: '',
        id: '516'
      },
      {
        title: 'Additional Mathematics',
        level: 'Junior',
        imagePath: '../../assets/Images/519.png',
        description: '',
        id: '519'
      },
      {
        title: 'Bookeeping & Accounts',
        level: 'Junior',
        imagePath: '../../assets/Images/520.png',
        description: '',
        id: '520'
      },
      {
        title: 'Business Studies',
        level: 'Junior',
        imagePath: '../../assets/Images/521.png',
        description: '',
        id: '521'
      },
      {
        title: 'Development Studies',
        level: 'Junior',
        imagePath: '../../assets/Images/524.png',
        description: '',
        id: '524'
      },
      {
        title: 'Geography',
        level: 'Junior',
        imagePath: '../../assets/Images/527.png',
        description: '',
        id: '527'
      },
      {
        title: 'History',
        level: 'Junior',
        imagePath: '../../assets/Images/530.png',
        description: '',
        id: '530'
      },
      {
        title: 'Religous Studies',
        level: 'Junior',
        imagePath: '../../assets/Images/533.png',
        description: '',
        id: '533'
      },
      {
        title: 'Siswati as a First Language',
        level: 'Senior',
        imagePath: '../../assets/Images/6870.png',
        description: '',
        id: '6870'
      },
      {
        title: 'Siswati as a Second Language',
        level: 'Senior',
        imagePath: '../../assets/Images/6871.png',
        description: '',
        id: '6871'
      },
      {
        title: 'English Language',
        level: 'Senior',
        imagePath: '../../assets/Images/6873.png',
        description: '',
        id: '6873'
      },
      {
        title: 'English Literature',
        level: 'Senior',
        imagePath: '../../assets/Images/6875.png',
        description: '',
        id: '6875'
      },
      {
        title: 'Mathematics',
        level: 'Senior',
        imagePath: '../../assets/Images/6880.png',
        description: '',
        id: '6880'
      },
      {
        title: 'Biology',
        level: 'Senior',
        imagePath: '../../assets/Images/6884.png',
        description: '',
        id: '6884'
      },
      {
        title: 'Physical Science',
        level: 'Senior',
        imagePath: '../../assets/Images/6888.png',
        description: '',
        id: '6888'
      },
      {
        title: 'Geography',
        level: 'Senior',
        imagePath: '../../assets/Images/6890.png',
        description: '',
        id: '6890'
      },
      {
        title: 'History',
        level: 'Senior',
        imagePath: '../../assets/Images/6891.png',
        description: '',
        id: '6891'
      },
      {
        title: 'Religous Education',
        level: 'Senior',
        imagePath: '../../assets/Images/6893.png',
        description: '',
        id: '6893'
      },
      {
        title: 'Accounts',
        level: 'Senior',
        imagePath: '../../assets/Images/6896.png',
        description: '',
        id: '6896'
      },
      {
        title: 'Business Studies',
        level: 'Senior',
        imagePath: '../../assets/Images/6897.png',
        description: '',
        id: '6897'
      },
      {
        title: 'Economics',
        level: 'Senior',
        imagePath: '../../assets/Images/6899.png',
        description: '',
        id: '6899'
      },
  ];
    // tslint:disable-next-line: prefer-for-of
    for(let i = 0; i < this.courses.length; i++) {
      if (this.courses[i].id === id) {
          this.course = this.courses[i];
          break;
      }
    }
  }
}
