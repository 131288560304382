export class ErrorDetails extends Error {
  public title: string;
  public status: number;
  public timeStamp: number;
  public message: string;


  constructor(message: string, title: string, status: number, timeStamp: number) {
    super(message);
    this.title = title;
    this.status = status;
    this.timeStamp = timeStamp;
    this.message = message;
  }
}
