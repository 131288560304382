import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {NewContactUsModel} from '../models/new-contact-us-model';
import {NewContactUsCompletedModel} from '../models/new-contact-us-completed-model';
import { ErrorDetails } from '../models/error-details';

const API_URL = environment.baseApiUrl;

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private readonly http: HttpClient,
  ) { }

  public async sendContactUsEmail(newContactUsModel: NewContactUsModel): Promise<NewContactUsCompletedModel> {
    try {
      // @ts-ignore
      return this
        .http
        .post<NewContactUsCompletedModel>(API_URL + '/contact-us', newContactUsModel)
        .toPromise();
    } catch (error) {
      console.log(error);
      if (error && error instanceof ProgressEvent) {
        throw new ErrorDetails('A connection could not be established. Please contact an administrator.',
          'Contact Administrator', null, null);
      }
      throw new ErrorDetails(error.error.detail, error.error.title || error.error.detail,
        error.error.status, error.error.timeStamp);
    }
  }
}
